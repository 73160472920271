require("./bootstrap");

window.$ = window.jQuery = require("jquery");

import * as Turbo from "@hotwired/turbo";

import "tw-elements";

import flatpickr from "flatpickr";
window.flatpickr = flatpickr;

import moment from "moment";
window.moment = moment;

import hljs from "highlight.js";
window.hljs = hljs;

// AlpineJS "defer" loading
import Alpine from "alpinejs";
import mask from "@alpinejs/mask";
window.addEventListener("DOMContentLoaded", function () {
    Alpine.plugin(mask);
    window.Alpine = Alpine;
    Alpine.start();
});

import "cropperjs/dist/cropper.css";
import Cropper from "cropperjs";
window.Cropper = Cropper;

require("select2");

$(function () {
    var flashTimer;

    $(document).on("turbo:visit", function () {
        // Hide Flashbox if navigating to different page and turn off timer
        if (window.location.pathname !== $("#flashBox").data("destPath")) {
            try {
                clearTimeout(flashTimer);
            } catch (e) {}
            createFlash($("#flashBox").data("type"), null);
        }
    });

    // Redirect via Turbo using destPath in Livewire emit on submitForm
    Livewire.hook("message.processed", (message, component) => {
        if (
            message.updateQueue[0].method == "submitForm" &&
            Array.isArray(component.serverMemo.errors)
        ) {
            try {
                Turbo.visit(
                    message.response.effects.emits[0].params[0].destPath
                );
            } catch (e) {}
        }
    });

    // When emitting flash, show message and hide after set time
    Livewire.on("flash", (event) => {
        createFlash(event.type, event.message, event.destPath);
        flashTimer = setTimeout(function () {
            createFlash(event.type, null);
        }, 8000);
    });

    // Catch flash messages for app pages (shows up as modal)
    Livewire.on("flashApp", (event) => {
        $("#flashModalMessage").empty().html(event.message);
        $("#flashModal").modal("show");
    });
});

// JSON object for Flash icons
var flashIcons = {
    success: '<i class="fa-solid fa-circle-check fa-fade mr-2"></i>',
    info: '<i class="fa-solid fa-circle-info fa-fade mr-2"></i>',
    warning: '<i class="fa-solid fa-triangle-exclamation fa-fade mr-2"></i>',
    error: '<i class="fa-solid fa-circle-exclamation fa-fade mr-2"></i>',
};
// Function to show Flash and redirect
function createFlash(type, message, destPath) {
    if (message != null) {
        // Empty Flash and add properties
        $("#flashBox")
            .empty()
            .html(flashIcons[type] + message)
            .removeClass("hidden")
            .addClass("flash-" + type)
            .data("type", type)
            .data("destPath", destPath);
    } else {
        // Destroy Flash
        $("#flashBox")
            .empty()
            .addClass("hidden")
            .removeClass("flash-" + type)
            .removeData("type")
            .removeData("destPath");
    }
}

// Possible keys for scrolling
// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

function preventDefault(e) {
    e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
    if (keys[e.keyCode]) {
        preventDefault(e);
        return false;
    }
}

// modern Chrome requires { passive: false } when adding event
var supportsPassive = false;
try {
    window.addEventListener(
        "test",
        null,
        Object.defineProperty({}, "passive", {
            get: function () {
                supportsPassive = true;
            },
        })
    );
} catch (e) {}
var wheelOpt = supportsPassive ? { passive: false } : false;
var wheelEvent =
    "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";

// Disable scrolling
function disableScroll() {
    window.addEventListener("DOMMouseScroll", preventDefault, false); // older FF
    window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
    window.addEventListener("touchmove", preventDefault, wheelOpt); // mobile
    window.addEventListener("keydown", preventDefaultForScrollKeys, false);
}

// Enable scrolling
function enableScroll() {
    window.removeEventListener("DOMMouseScroll", preventDefault, false);
    window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
    window.removeEventListener("touchmove", preventDefault, wheelOpt);
    window.removeEventListener("keydown", preventDefaultForScrollKeys, false);
}

// Expose scroll events to window
window.disableScroll = disableScroll;
window.enableScroll = enableScroll;
